import React from "react"
import { motion } from 'framer-motion'
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'

const Hero = ({ h1, h2 }) => (
    
    <section role="main">
      <div className="container">
        <div className="row flex flex-jc">
          <div className="hero-content sub-hero">
            <h1
              className="small-h"
            >{h1}</h1>
            <motion.h2 
              className="hero-lead"
              initial="hidden"
              animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [80, 0] }} 
              transition={{ ease: "easeOut", duration: 0.5 }}
            >
                {h2}
            </motion.h2>
            <div className="btn-row">
              <motion.div 
                initial="hidden"
                animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], x: [-80, 0] }} 
                transition={{ ease: "easeOut", duration: 0.5 }}
              > 
                <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-purple"
                >
                  <span className="flex flex-ac down">
                    <span className="visually-hidden">Clicking this button will scroll the screen down to the next text section on this page.</span>
                    <ArrowRight />
                  </span>
                </button>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
)

export default Hero